import React from 'react';

import Layout from 'components/Foundation/Layout';
import SEO from 'components/Foundation/SEO';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';

const Events: React.FC = () => (
    <Layout>
        <SEO title="Events" />
        <Container section>
        <TitleSection title="Events" subtitle="Under Construction" center />
        <p className="mt-4 text-center w-full">Will be available soon ...</p>
        </Container>
    </Layout>
);

export default Events;
